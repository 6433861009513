.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginBackground {
  background: linear-gradient(rgba(81, 12, 12, 0.75), rgba(230, 55, 30, 0.62)), url('assets/login_background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.homeBackground {
  background: white;
  background-size: cover;
}

button {
  background-color: #FF0808;
  padding: 12px 25px;
  border: none;
  border-radius: 25px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  font-weight: 500;
  color: white;
}

button:hover {
  background-color: #8e0808;
}

button:disabled {
  background-color: rgba(61, 107, 122, 0.432);
  cursor: default;
}

input {
  padding: 12px 20px;
  margin: 10px;
  border: 2px solid black;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
}

input:focus {
  outline: none;
  border-color: black;
  box-shadow: 0 0 10px black;
}

.animatedButton {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: ew-resize;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

.home-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.menu {
  display: flex;
  justify-content: space-between;
}

.list-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.list-container ul {
  list-style: none;
  padding: 0;
}

.list-container li {
  padding: 8px;
  border: 1px solid #ddd;
  margin: 8px 0;
}

.document-tile {
  width: 210px;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  overflow: hidden;
}

.app-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
}

.document-tile:hover .overlay {
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(211, 211, 211, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
}

.overlay span {
  font-size: 20px;
}

.document-logo {
  width: 60px;
}

.documents-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.documents-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-bar {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid black;
}

.fab {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #FF0808;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.fab-icon {
  font-size: 24px;
}

.fab:hover {
  background-color: a24000;
}

.pdf-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
}

.close-button {
  position: fixed;
  top: 20px;
  left: 20px;
  background: #8e0808;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 1000;
}

.pdf-page-canvas {
  display: block;
  margin: 5px auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 100%;
  height: auto;
}

.download-button {
  position: fixed;
  top: 10px;
  right: 30px;
  z-index: 1000;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 3px solid #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

body.downloading {
  cursor: wait;
}

.button-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.sidebar {
  flex: 0 0 250px;
  background-color: #333f50;
  border-right: 1px solid #ddd;
  padding: 20px;
}

.sidebar-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  background-color: #FF0808;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: white;
}

.sidebar-button:hover {
  background-color: #8e0808;
}

.sidebar-icon {
  margin-right: 10px;
}

.sidebar-logo {
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: center;
}

.sidebar-title {
  font-size: 18px;
  margin-right: 10px;
  margin-left: 10px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: white;
}

.sidebar-divider {
  border-bottom: 2px solid white;
  margin: 10px;
}

.sidebar-footer {
  margin-top: auto;
}

.sidebar-button-logout {
  background-color: red;
  color: white;
}

.sidebar-button-logout:hover {
  background-color: darkred;
}

.user-profile {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2000;
  width: 400px;
}

.user-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
}

.strong-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 3;
}

.stronger-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1001;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: white;
  margin: 0 20px;
  text-align: center;
}

.small-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: white;
  margin: 0 20px;
  text-align: center;
}

.icon {
  font-size: 24px;
  color: lightgrey;
  cursor: pointer;
}

.back-icon {
  flex-grow: 0;
}

.back-icon-transparent {
  flex-grow: 0;
  color: transparent;
}

.edit-icon {
  flex-grow: 0;
}

.edit-icon-transparent {
  flex-grow: 0;
  color: transparent;
}

.delete-icon {
  flex-grow: 0;
  color: black;
}

.delete-icon-transparent {
  flex-grow: 0;
  color: transparent;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left-aligned {
  display: flex;
  align-items: center;
  justify-content: left;
}

.divider {
  height: 1px;
  background-color: lightgray;
  margin: 20px 0;
}

.vertical-divider {
  width: 1px;
  background-color: lightgray;
  margin: 0px 20px;
}

.user-info {
  text-align: center;
  padding: 10px;
}

.tooltip {
  position: relative;
}

.tooltip-text {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip input:focus+.tooltip-text {
  visibility: visible;
  opacity: 1;
}

.user-list {
  position: fixed;
  top: 20%;
  left: 30%;
  right: 30%;
  bottom: 20%;
  background-color: white;
  border-radius: 10px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.nav-bar {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ccc;
}

.search-input {
  padding: 10px;
  border-radius: 25px;
  border: none;
}

.icon.back-icon {
  cursor: pointer;
}

.user-list-content {
  padding: 15px;
}

.user-item {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-item:hover {
  background-color: #f0f0f0;
}

.user-list-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.send-button {
  color: white;
  padding: 8px 12px;
  margin-left: auto;
  border: none;
  cursor: pointer;
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 0, 0, 0.6);
  color: white;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 3px;
}

.info-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  font-size: 28px;
}


.info-tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid black;
  padding: 5px;
  border-radius: 3px;
}


.delete-button:hover {
  background-color: rgba(255, 0, 0, 1);
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.login-logo-container,
.login-logo-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.login-logo-container {
  flex-direction: column;
}

.login-logo {
  width: 70%;
  height: auto;
}

.login-logo-big {
  width: 120%;
  height: auto;
  margin-bottom: 80px;
}

span {
  color: white;
  margin-top: 0.5rem;
}

span.digital-contracts {
  font-size: 1.8rem;
  font-weight: bold;
  color: white;
}

span.catena {
  font-size: 1.4rem;
  text-transform: uppercase;
}

span.powered-by {
  font-size: 1.2rem;
    color: white;
}

input,
.login-button {
  margin-top: 0.5rem;
}

.login-button {
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
}

.welcome-message {
  color: white;
  font-size: 1.2em;
}

.register-link-container {
  margin-top: 10px;
  text-align: center;
}

.register-link {
  color: white;
  cursor: pointer;
  font-size: 1em;
  text-decoration: underline;
}

.download-message {
  color: black;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-container {
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.document-img {
  max-width: 90%;
  max-height: 90%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.txt-display {
  width: 40%;
  
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.8);
  z-index: 1;

  max-height: 90%;
  flex-direction: column;
  overflow-y: auto;
}

.horizontal-div {
  display: flex;
}

.add-right-user {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  width: 400px;
}

.uid-uname-chip {
  text-align: center;
  background-color: white;
  color: black;
}

.input-textfield input {
  padding: none;
  margin: none;
  border: none;
  border-radius: none;
  font-size: 1rem;
  outline: none;
  box-shadow: none;
  margin-top: none;
}

.asset-type-info {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 600px;

  max-height: 90%;
  flex-direction: column;
  overflow-y: auto;
}

.checkbox {
  height: 25px;
}

.asset-type-param-table tr {
  height: 1px;
}

.asset-type-param-table tr td {
  height: auto;
}

tr.asset-type-param-table-wide td {
  height: 43px;
}

tr.asset-type-param-table-wider td {
  height: 60px;
}

.divider-asset-type {
  height: 1px;
  background-color: lightgray;
  margin: 10px 0;
}

.clickable-icon {
  cursor: pointer;
}

.new-asset-type-param-frame {
  position: fixed;
  top: 20%;
  left: 71%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  width: 400px;

  max-height: 60%;
  flex-direction: column;
  overflow-y: auto;
}

.choose-asset-type-for-new-asset-frame {
  position: fixed;
  top: 30%;
  left: 27%;
  transform: translate(-50%, 0%);
  background: white;
  padding: 20px;
  padding-top: 0px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 400px;
}

.asset-info {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  width: 630px;
}

.asset-info-create-new {
  position: fixed;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 630px;
}

.underlined {
  text-decoration: underline;
}

.right-align {
  text-align: right;
}

.left-align-pad-left {
  text-align: left;
  padding-left: 15px;
}

.select-mid-height {
  height: 40px;
}

.log-data-table {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 4;
  width: 630px;

  max-height: 60%;
  flex-direction: column;
  overflow-y: auto;
}

.csv-display {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 4;
  
  max-width: 80%;
  max-height: 80%;
  flex-direction: column;
  overflow-y: auto;
}

span {
  color: black;
}

.datagrid svg {
  color: black;
  padding-bottom: 10px;
}

.datagrid button {
  background-color: #FF0808;

  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 500;
  color: white;
  align-items: center;
}

.datagrid button:hover {
  background-color: #8e0808;
}

.add-log-data-row-frame {
  text-align: center;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: auto;

  max-height: 60%;
  flex-direction: column;
  overflow-y: auto;
}

.add-log-data-row-frame table {
  align-self: center;
  margin: auto;
}

.log-data-grid-cell {
  border: 1px solid black;
}

.button-new-log-entry {
  background-color: #FF0808;
  padding: 0px 5px 0px 10px;
  border: none;
  border-radius: 25px 0px 0px 25px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 500;
  color: white;
  align-items: center;
  margin: 10px 0px 10px 0px;
  height: 35px;
}

.button-show-log-entries {
  background-color: #FF0808;
  padding: 0px 5px 0px 10px;
  border: none;
  border-radius: 0px 25px 25px 0px;
  border-left: grey 1px solid;

  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 500;
  color: white;
  align-items: center;
  margin: 10px 0px 10px 0px;
  height: 35px;
}

.test-horizontal {
  display: flex;
}

.rentable-frame {
  text-align: center;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: auto;

  display: flex;
}

.rentable-tile {
  width: 230px;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  overflow: hidden;
}

.rentable-tile:hover .overlay {
  opacity: 1;
}

.small-button {
  background-color: #FF0808;
  padding: 0px 5px 0px 10px;
  border: none;

  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 500;
  color: white;
  align-items: center;
  margin: 0px 0px 10px 0px;
  height: 35px;
}

.asset-history-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.expand-btn {
  padding: 5px 10px;
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  background-color: #FF0808;
}

.asset-history-list table {
  width: 100%;
  border-collapse: collapse;
}

.asset-history-list th,
.asset-history-list td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.detail-row {
  background-color: #f2f2f2;
}

.history-tile {
  width: auto;
  max-width: 90%;
  border: 1px solid #ccc;
  background-color: white;
  padding: 10px;
  max-height: 800px;
  text-align: center;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  justify-content: center;
  margin: 30px auto;
  overflow-y: auto;
}

.positive-amount {
  color: green;
}

.negative-amount {
  color: red;
}

